import { PropsWithChildren, createContext, useContext, useState } from 'react';

export interface DeeplinkProviderState {
  countryCode?: string;
  vendorId?: string;

  path?: Bees.DeeplinkGeneratorPath;
  store?: Bees.DeeplinkGeneratorStore;
}

const initialValue: DeeplinkProviderState = { countryCode: 'BR' };

export const DEEPLINK_CONTEXT = createContext<
  [DeeplinkProviderState, React.Dispatch<React.SetStateAction<DeeplinkProviderState>>]
>([initialValue, undefined]);

export const useDeeplinkState = () => useContext(DEEPLINK_CONTEXT);

function DeeplinkProvider({ children }: PropsWithChildren<{}>) {
  const [state, setState] = useState<DeeplinkProviderState>(initialValue);

  return (
    <DEEPLINK_CONTEXT.Provider value={[state, setState]}>{children}</DEEPLINK_CONTEXT.Provider>
  );
}
export default DeeplinkProvider;
