import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  inputs: {
    countrySelection: {
      label: 'Country',
    },
    vendorSelection: {
      label: 'Vendor',
    },
  },
  sidebar: {
    generator: 'Deep link generator',
    monitoring: 'Monitoring',
  },
  button: {
    back: 'Go Back',
    next: 'Next',
  },
  countryVendorPage: {
    title: 'Deep link generator',
    content: {
      title: 'Country and vendor',
      description: 'Select for what country and vendor you want to create the deep link.',
    },
  },
  pathSelectionPage: {
    title: 'Path Selection',
    content: {
      title: 'Path',
      description: 'Select where you want to create the deep link.',
    },
  },
  storeSelectionPage: {
    title: 'Select a store',
    content: {
      title: 'Select a store',
      description: 'Select a store to continue.',
    },
  },
  resultPage: {
    title: 'Create deep link',
    title2: 'Deep link successfully created',
    content: {
      description: 'Your deep link is active and ready to be used.',
    },
  },
};

export default enUS;
