import { Grid } from '@hexa-ui/components';
import { Linked, PieChart } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import { useSidebar } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import Router from '../Router';

const AppBarConfigProvider = (): JSX.Element => {
  const LinkedIcon = () => <Linked />;
  const PieChartIcon = () => <PieChart />;
  const intl = useIntl();

  useSidebar({
    items: [
      {
        id: '1',
        title: intl.formatMessage({ id: 'sidebar.generator' }),
        icon: LinkedIcon,
        path: '/deeplink',
      },
      {
        id: '1',
        title: intl.formatMessage({ id: 'sidebar.monitoring' }),
        icon: PieChartIcon,
        path: '/deeplink/monitoring',
      },
    ],
    utils: [],
  });

  return (
    <StyledGrid type="fluid" sidebar>
      <Router />
      <Grid.Item xl={9} lg={9} md={9} />
    </StyledGrid>
  );
};

export default AppBarConfigProvider;

const StyledGrid = styled(Grid.Container, {
  paddingTop: '$4',
  rowGap: '1rem',
  '@desktopSmall': {
    paddingTop: 0,
  },
});
