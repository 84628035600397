import { LoadingDots } from '@hexa-ui/components';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const CountryVendorSelectionPage = lazy(
  () => import('./pages/CountryVendorSelectionPage/CountryVendorSelectionPage')
);
const PathSelectionPage = lazy(() => import('./pages/PathSelectionPage/PathSelectionPage'));
const StoreSelectionPage = lazy(() => import('./pages/StoreSelectionPage/StoreSelectionPage'));
const MonitoringPage = lazy(() => import('./pages/MonitoringPage/MonitoringPage'));
const ZoneMonitoringPage = lazy(() => import('./pages/ZoneMonitoringPage/ZoneMonitoringPage'));
const GenerateDeeplinkPage = lazy(
  () => import('./pages/GenerateDeeplinkPage/GenerateDeeplinkPage')
);

const BASENAME = '/deeplink';
const Router = (): JSX.Element => {
  return (
    <Suspense fallback={<LoadingDots />}>
      <BrowserRouter>
        <Routes>
          <Route path={`${BASENAME}/`} element={<CountryVendorSelectionPage />} />
          <Route path={`${BASENAME}/path`} element={<PathSelectionPage />} />
          <Route path={`${BASENAME}/path/store`} element={<StoreSelectionPage />} />
          <Route path={`${BASENAME}/path/store/result`} element={<GenerateDeeplinkPage />} />

          <Route path={`${BASENAME}/monitoring`} element={<MonitoringPage />} />
          <Route path={`${BASENAME}/monitoring/:country`} element={<ZoneMonitoringPage />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};
export default Router;
